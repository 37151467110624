import { Utils } from '@kw-shared/common';
import { Observable } from 'rxjs';
import { BaseModel } from '../../../../models';
import { AdvancedHttpClient, RequestParams } from '../../http/AdvancedHttpClient';
import { DataWithCount } from '../../types/DataWithCount';
import { Filter } from '../../types/Filter';
import { Pagination } from '../../types/Pagination';
import { Sort } from '../../types/Sort';
import { IRepository } from './IRepository';

export class WebDataRepository<T extends BaseModel> implements IRepository<T> {
  constructor(
    protected httpClient: AdvancedHttpClient,
    protected resource: string
  ) {}

  public getAll(
    filters?: Filter[],
    sort?: Sort<T>[],
    pagination?: Pagination,
    path?: string
  ): Observable<DataWithCount<T>> {
    const queryParams: RequestParams = {};
    this.addFilterQueryParams(queryParams, filters);
    this.addSortQueryParams(queryParams, sort);

    if (pagination) {
      this.addPaginationQueryParams(queryParams, pagination);
    }

    const url = path ? Utils.joinPaths(this.resource, path) : this.resource;

    return this.httpClient.get<DataWithCount<T>>({ url, queryParams });
  }

  public getAllUnpaged(filters?: Filter[], sort?: Sort<T>[], path?: string): Observable<DataWithCount<T>> {
    return this.getAll(filters, sort, undefined, path);
  }

  public getById(id: string): Observable<T> {
    return this.httpClient.get<T>({ url: `${this.resource}/${id}` });
  }

  public add(entity: T): Observable<T> {
    return this.httpClient.post<T>({ url: this.resource, body: entity });
  }

  public update(entity: Partial<T>): Observable<void> {
    return this.httpClient.put<void>({ url: `${this.resource}/${entity.id}`, body: entity });
  }

  public delete(entity: Partial<T>): Observable<void> {
    return this.httpClient.delete<void>({ url: `${this.resource}/${entity.id}` });
  }

  protected addFilterQueryParams(queryParams: RequestParams, filter?: Filter[]) {
    filter?.forEach(f => {
      let stringValue: string;
      if (Array.isArray(f.value)) {
        stringValue = f.value.join(',');
      } else {
        stringValue = String(f.value);
      }

      queryParams[`filter[${String(f.fieldName)}]`] = stringValue;
    });
  }

  protected addPaginationQueryParams(queryParams: RequestParams, pagination?: Pagination) {
    if (!pagination) {
      // add default pagination
      pagination = new Pagination(0, 10);
    }
    queryParams['page'] = pagination.pageNumber.toString();
    queryParams['pageSize'] = pagination.pageSize.toString();
  }

  protected addSortQueryParams(queryParams: RequestParams, sort?: Sort<T>[]) {
    if (sort) {
      queryParams['sort'] = sort.join(',');
    }
  }
}
