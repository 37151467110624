import { HttpResponse } from '@angular/common/http';
import {
  RemoteChargingSessionRfidCard,
  RemoteChargingSessionsStatistics,
  RemoteWallboxChargingSession,
  RemoteWallboxChargingSessionWallboxFilter,
} from '@kw-shared/api-data-access';
import { Utils } from '@kw-shared/common';
import { Observable, catchError, map, of } from 'rxjs';
import { AdvancedHttpClient, RequestData, RequestParams } from '../../http/AdvancedHttpClient';
import { DataAggregationPeriod } from '../../types/DataAggregationPeriod';
import { DataWithCount } from '../../types/DataWithCount';
import { Filter } from '../../types/Filter';
import { Pagination } from '../../types/Pagination';
import { Sort, SortDirection } from '../../types/Sort';
import { WebDataRepository } from '../base/WebDataRepository';
import { ChargingSessionsSummary, IChargingSessionsRepository } from './IChargingSessionsRepository';

export class ChargingSessionsRepository
  extends WebDataRepository<RemoteWallboxChargingSession>
  implements IChargingSessionsRepository
{
  constructor(
    public override httpClient: AdvancedHttpClient,
    private basePath?: string
  ) {
    super(httpClient, Utils.joinPaths(basePath, 'transactions'));
  }

  public getChargingSessionsByWallboxId(
    wallboxId: string,
    filters?: Filter[],
    sort?: Sort<RemoteWallboxChargingSession>[],
    pagination?: Pagination
  ): Observable<DataWithCount<RemoteWallboxChargingSession>> {
    return this.getAll(filters, sort, pagination);
  }

  public getCurrentChargingSessionsByWallboxId(
    wallboxId: string,
    serialNumber?: string
  ): Observable<RemoteWallboxChargingSession | undefined> {
    const result = this.httpClient.get({ url: `${this.resource}/${wallboxId}/current` }).pipe(
      catchError(error => {
        if (error.status === 404) {
          // need to get last charging session for showing time and charged energy
          let filters: Filter[] = [];

          filters.push(new Filter('wallboxes', [serialNumber]));

          return this.getChargingSessionsByWallboxId(
            wallboxId,
            filters,
            [new Sort('start', SortDirection.Descending)],
            new Pagination(0, 1)
          ).pipe(map(result => Utils.first(result.list)!));
        }
        return of(undefined);
      })
    );
    return result as Observable<RemoteWallboxChargingSession | undefined>;
  }

  public getCurrentChargingSessionsByWallboxIdAndClientChargePointId(
    wallboxId: string,
    clientChargePointId: string,
    serialNumber?: string
  ): Observable<RemoteWallboxChargingSession | undefined> {
    const url = `${this.resource}/${wallboxId}/current/${clientChargePointId}`;

    const result = this.httpClient.get({ url: url }).pipe(
      catchError(error => {
        if (error.status === 404) {
          // need to get last charging session for showing time and charged energy
          let filters: Filter[] = [];

          filters.push(new Filter('wallboxes', [serialNumber]));
          return this.getChargingSessionsByWallboxId(
            wallboxId,
            filters,
            [new Sort('start', SortDirection.Descending)],
            new Pagination(0, 1)
          ).pipe(map(result => Utils.first(result.list)!));
        }
        return of(undefined);
      })
    );
    return result as Observable<RemoteWallboxChargingSession | undefined>;
  }

  public getCurrentChargingSessionsStatusByWallboxId(wallboxId: string): Observable<number> {
    const requestData: RequestData = {
      url: `${this.resource}/${wallboxId}/current`,
      observe: 'response', // Specify that you want the full response
    };

    return this.httpClient.getWithObserve<HttpResponse<unknown>>(requestData).pipe(
      map((response: HttpResponse<unknown>) => {
        return response.status;
      }),
      catchError(error => {
        return of(error.status);
      })
    );
  }

  public getCurrentChargingSessionsStatusByWallboxIdAndClientChargePointId(
    wallboxId: string,
    clientChargePointId: string
  ): Observable<number> {
    const requestData: RequestData = {
      url: `${this.resource}/${wallboxId}/current/${clientChargePointId}`,
      observe: 'response', // Specify that you want the full response
    };

    return this.httpClient.getWithObserve<HttpResponse<unknown>>(requestData).pipe(
      map((response: HttpResponse<unknown>) => {
        return response.status;
      }),
      catchError(error => {
        return of(error.status);
      })
    );
  }

  public startChargingSession(wallboxId: string, connectorId: number): Observable<void> {
    return this.httpClient.put<void>({
      url: `${this.resource}/start`,
      body: {
        chargePointId: wallboxId, // multiple connectors aren't implemented at the moment will be handled by the backend.
        connectorId: connectorId,
      },
      requestContext: 'context_wallbox_name',
    });
  }

  public stopChargingSession(wallboxId: string, transactionGuid: string): Observable<void> {
    return this.httpClient.put<void>({
      url: `${this.resource}/stop`,
      body: {
        chargePointId: wallboxId,
        id: transactionGuid,
      },
      requestContext: 'context_wallbox_name',
    });
  }

  public getAllWithSummary(
    filter?: Filter[],
    sort?: Sort<RemoteWallboxChargingSession>[],
    pagination?: Pagination
  ): Observable<ChargingSessionsSummary> {
    return this.getAll(filter, sort, pagination) as Observable<
      DataWithCount<RemoteWallboxChargingSession> & { summary: RemoteChargingSessionsStatistics }
    >;
  }

  public getAggregated(
    period: DataAggregationPeriod,
    filter?: Filter[]
  ): Observable<{ startDate: string; value: number }[]> {
    const queryParams: RequestParams = { period };
    this.addFilterQueryParams(queryParams, filter);
    return this.httpClient.get({ url: `${this.resource}/aggregated`, queryParams });
  }

  public getAvailableFilterTags(): Observable<string[]> {
    return this.httpClient.get<string[]>({ url: `${this.resource}/filter/tags` });
  }

  public getAvailableFilterWallboxes(): Observable<RemoteWallboxChargingSessionWallboxFilter[]> {
    return this.httpClient.get<RemoteWallboxChargingSessionWallboxFilter[]>({
      url: `${this.resource}/filter/chargePoints`,
    });
  }

  public getAvailableFilterRfidCardsForSingleWallbox(wallboxId: string): Observable<RemoteChargingSessionRfidCard[]> {
    return this.httpClient.get<RemoteChargingSessionRfidCard[]>({
      url: `${this.resource}/${wallboxId}/filter/rfidData`,
    });
  }
}
