<div class="flex justify-center">
  <div class="w-11/12">
    <div class="flex justify-center h-0 mb-8 text-4xl text-center">
      <span class="truncate">{{ currentValue }}</span>
      <span *ngIf="unit" class="ml-2">{{ unit }}</span>
    </div>

    <input
      type="range"
      class="w-full"
      [disabled]="disabled"
      [min]="hasDiscreteValues() ? 0 : min"
      [max]="hasDiscreteValues() ? (possibleValues?.length ?? 1) - 1 : max"
      [step]="hasDiscreteValues() ? 1 : 0.1"
      [value]="hasDiscreteValues() ? getDiscreteValueIndex(defaultValue) : defaultValue"
      (input)="onSliderChange($event)"
      [attr.accessibility-id]="accessibilityId"
    />

    <div class="flex justify-between px-2 mb-6 text-iron-500">
      <div>
        <div class="font-light">{{ 'kwa_lbl_slider_min' | translate }}</div>
        <span class="font-medium">{{ min | format: format:formatMax }} {{ unit }}</span>
      </div>
      <div class="text-right">
        <div class="font-light">{{ 'kwa_lbl_slider_max' | translate }}</div>
        <span class="font-medium">{{ max | format: format:formatMax }} {{ unit }}</span>
      </div>
    </div>
  </div>
</div>
