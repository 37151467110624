import { Validators } from '@angular/forms';
import { ConfigurationType, LmgmtKey } from '@keba-wallbox-plugin/plugin';
import { AdditionalCall, FormInputType, SettingsFormData } from '../../components';
import { SettingsUnitsConverter } from '../../utils';
import { SettingsValidators } from '../../utils/SettingsValidators';
import { InstallerModeLockKey } from '../installer-mode-lock-keys.definition';
import { WallboxConnectionType } from '../wallbox-connection-type.definition';
import { x1x2SettingsDefinition } from './settings-form.x1-x2.definition';

export const installerSettingsFormDefinition: SettingsFormData[] = [
  {
    hideSeparator: true,
    hasGrayHeader: true,
    pageNoteDescription: 'kwa.p40.occp-note',
    pageNoteDescriptionPopup: {
      title: 'kwa.p40.occp-note-popup.title',
      text: 'kwa.p40.occp-note-popup.text',
      mediaUrl: 'kwa.p40.occp-note-popup.media-url',
    },
    elements: [
      {
        key: 'installer-settings',
        type: FormInputType.INSTALLER_SETTINGS,
        hideElement: true,
        hideConditions: {
          operator: 'or',
          conditions: [{ condition: 'connectionType', value: WallboxConnectionType.REMOTE }],
        },
        subSteps: [
          {
            headline: 'kwa.settings.installer-settings.sub-steps.headline',
            title: 'kwa.settings.installer-settings.connect-via-bluetooth.title',
            showBluetoothStatus: true,
            showConnectToBluetoothButton: true,
          },
          {
            title: 'kwa.settings.installer-settings.service-button.title',
            showServiceButtonStatus: true,
            info: {
              title: 'kwa.settings.installer-settings.service-button.info.title',
              mediaUrl: 'kwa.settings.installer-settings.service-button.info.media-url',
              text: 'kwa.settings.installer-settings.service-button.info.description',
            },
          },
        ],
      },
      {
        key: 'max_current',
        type: FormInputType.NUMBER,
        additionalCallType: AdditionalCall.INSTALLER_SETTINGS_MAX_CURRENT,
        description: 'kwa.settings.installer-settings.max-current.description',
        title: 'kwa.settings.installer-settings.max-current.title',
        min: 6,
        max: 32,
        validators: [SettingsValidators.maxCurrentValidator, Validators.min(6), Validators.max(32)],
        convertToDisplayUnit: SettingsUnitsConverter.milliAmpereToAmpere,
        convertFromDisplayUnit: SettingsUnitsConverter.ampereToMilliAmpere,
        accessibilityId: 'installer-settings-max-current',
        readOnly: false,
        errorTranslationMap: {
          min: 'kwa.settings.installer-settings.max-current.requirement.min',
          max: 'kwa.settings.installer-settings.max-current.requirement.max',
        },
      },
      {
        key: LmgmtKey.NOMINAL_VOLTAGE,
        type: FormInputType.SELECT,
        description: 'kwa.settings.installer-settings.nominal-voltage.description',
        title: 'kwa.settings.installer-settings.nominal-voltage.title',
        configurationType: ConfigurationType.LMGMT,
        accessibilityId: 'installer-settings-nominal-voltage',
        readOnly: false,
        selectOptions: [
          { key: '100', text: '100', accessibilityId: 'installer-settings-select-nominal-voltage-100' },
          { key: '110', text: '110', accessibilityId: 'installer-settings-select-nominal-voltage-110' },
          { key: '115', text: '115', accessibilityId: 'installer-settings-select-nominal-voltage-115' },
          { key: '120', text: '120', accessibilityId: 'installer-settings-select-nominal-voltage-120' },
          { key: '127', text: '127', accessibilityId: 'installer-settings-select-nominal-voltage-127' },
          { key: '220', text: '220', accessibilityId: 'installer-settings-select-nominal-voltage-220' },
          { key: '230', text: '230', accessibilityId: 'installer-settings-select-nominal-voltage-230' },
          { key: '240', text: '240', accessibilityId: 'installer-settings-select-nominal-voltage-240' },
        ],
      },
      {
        key: 'phase_used',
        type: FormInputType.SELECT,
        additionalCallType: AdditionalCall.INSTALLER_SETTINGS_MAX_CURRENT_PHASE_USED,
        description: 'kwa.settings.installer-settings.phase-used.description',
        title: 'kwa.settings.installer-settings.phase-used.title',
        accessibilityId: 'installer-settings-phase-used',
        readOnly: false,
        selectOptions: [
          {
            key: 'L1',
            text: 'L1',
            accessibilityId: 'installer-settings-select-phase-used-l1',
          },
          {
            key: 'L2',
            text: 'L2',
            accessibilityId: 'installer-settings-select-phase-used-l2',
          },
          {
            key: 'L3',
            text: 'L3',
            accessibilityId: 'installer-settings-select-phase-used-l3',
          },
          {
            key: 'L1_L2_L3',
            text: 'L1_L2_L3',
            accessibilityId: 'installer-settings-select-phase-used-l1-l2-l3',
          },
          {
            key: 'L2_L3_L1',
            text: 'L2_L3_L1',
            accessibilityId: 'installer-settings-select-phase-used-l2-l3-l1',
          },
          {
            key: 'L3_L1_L2',
            text: 'L3_L1_L2',
            accessibilityId: 'installer-settings-select-phase-used-l3-l1-l2',
          },
          {
            key: 'L1_L3_L2',
            text: 'L1_L3_L2',
            accessibilityId: 'installer-settings-select-phase-used-l1-l3-l2',
          },
          {
            key: 'L2_L1_L3',
            text: 'L2_L1_L3',
            accessibilityId: 'installer-settings-select-phase-used-l2-l1-l3',
          },
          {
            key: 'L3_L2_L1',
            text: 'L3_L2_L1',
            accessibilityId: 'installer-settings-select-phase-used-l3-l2-l1',
          },
        ],
      },
      ...x1x2SettingsDefinition,
      {
        key: LmgmtKey.MAX_ASYM_PHASE_CURRENT,
        type: FormInputType.NUMBER,
        description: 'kwa.settings.installer-settings.max-asym-current.description',
        title: 'kwa.settings.installer-settings.max-asym-current.title',
        configurationType: ConfigurationType.LMGMT,
        max: 32,
        pattern: /^(0|[6-9]|\d{2,})$/,
        patternText: 'kwa.settings.installer-settings.max-asym-current.requirement.zero-or-min',
        // value needs to be <= than max HW current and between 0 and 32
        validators: [Validators.min(0), Validators.max(32), SettingsValidators.maxAsymPhaseCurrentValidator],
        convertToDisplayUnit: SettingsUnitsConverter.milliAmpereToAmpere,
        convertFromDisplayUnit: SettingsUnitsConverter.ampereToMilliAmpere,
        accessibilityId: 'installer-settings-max-asym-phase-current',
        readOnly: false,
      },
    ],
  },
  {
    showAsSubPage: true,
    hasGrayHeader: true,
    title: 'kwa.settings.installer-settings.lock.title',
    description: 'kwa.settings.installer-settings.lock.description',
    hideConditions: {
      operator: 'or',
      conditions: [{ condition: 'featureFlag', value: 'hideX1X2Settings' }],
    },
    elements: [
      {
        key: InstallerModeLockKey.LOCK_X1A,
        type: FormInputType.TOGGLE,
        title: 'kwa.settings.installer-settings.x1a.title',
        description: 'kwa.settings.installer-settings.x1a.description',
        readOnly: false,
        additionalCallType: AdditionalCall.INSTALLER_LOCK_SETTINGS,
      },
      {
        key: InstallerModeLockKey.LOCK_X1B,
        type: FormInputType.TOGGLE,
        title: 'kwa.settings.installer-settings.x1b.title',
        description: 'kwa.settings.installer-settings.x1b.description',
        readOnly: false,
        additionalCallType: AdditionalCall.INSTALLER_LOCK_SETTINGS,
      },
      {
        key: InstallerModeLockKey.LOCK_X2,
        type: FormInputType.TOGGLE,
        title: 'kwa.settings.installer-settings.x2.title',
        description: 'kwa.settings.installer-settings.x2.description',
        readOnly: false,
        additionalCallType: AdditionalCall.INSTALLER_LOCK_SETTINGS,
      },
    ],
  },
];
