import {
  DiagnosticsKey,
  DisplayTextKey,
  ExtMeterKey,
  LmgmtKey,
  NetworkKey,
  RfidKey,
} from '@keba-wallbox-plugin/plugin';
import { MaxPhases, PhaseUsed, UnitConverter, WallboxPVStatus } from '@kw-shared/common';
import { addSeconds, subHours } from 'date-fns';
import { Observable, map, of } from 'rxjs';
import {
  RemoteWallbox,
  RemoteWallboxConnectionStatus,
  RemoteWallboxState,
  RemoteWallboxUpdateState,
} from '../../../../models';
import { DataWithCount } from '../../types/DataWithCount';
import { Filter } from '../../types/Filter';
import { Pagination } from '../../types/Pagination';
import { Sort } from '../../types/Sort';
import { FakeRepository } from '../base/FakeRepository';
import { IWallboxRepository } from './IWallboxRepository';
import { RemoteWallboxFirmwareUpdateInfoDTO } from './dto/RemoteWallboxFirmwareUpdateInfoDTO';
import {
  RemoteWallboxSettingsChargepointAvailabilityDTO,
  RemoteWallboxSettingsConfiguration,
  RemoteWallboxSettingsDTO,
} from './dto/RemoteWallboxSettingsDTO';

const fakeWallboxPowerSettings: RemoteWallboxSettingsConfiguration = {
  max_available_current: '25114',
  nominal_voltage: '230',
  connector_phase_rotation: 'L1_L2_L3',
  min_default_current: '6000',
  max_current: '6000',
};

export class FakeWallboxRepository extends FakeRepository<RemoteWallbox> implements IWallboxRepository {
  constructor() {
    super([
      {
        name: 'My favorite',
        state: RemoteWallboxState.CHARGING,
        serialNumber: '123',
        model: 'KC-P30-......C.-.',
        id: 'w-123',
        connectorId: 1,
        firmwareVersion: '1.15.0',
        requiredFirmwareVersion: '1.15.0',
        updateIndication: RemoteWallboxUpdateState.CRITICAL_UPDATE,
        pvStatus: {
          status: WallboxPVStatus.PV_BOOST,
          endOfBoost: addSeconds(new Date(), 100).toISOString(),
        },
        tags: [
          { id: '1', name: 'Home' },
          { id: '3', name: 'Private' },
        ],
        configuration: fakeWallboxPowerSettings,
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
      {
        id: '672a4353abc41d5e0b9ef34e',
        name: 'P40 Online',
        state: RemoteWallboxState.ONLINE,
        lastStateChange: new Date(),
        serialNumber: '28707760',
        connectorId: 1,
        tags: [],
        totalChargedEnergy: 0,
        firmwareVersion: '1.1.0',
        model: 'KC-P40-16EU0-C6S1AE00-LS1R1111BL0-WH00',
        pvStatus: {
          status: WallboxPVStatus.PV_PRE_CHARGING,
          endOfBoost: '2024-10-22T11:07:22.503851Z',
        },
        configuration: {
          TimeSynchronizationQuality: 'STRONG',
          max_days_of_logs: '90',
          external_meter_home_grid_address: '123.123.0.1',
          ocpp_proxy_enable: 'false',
          ocpp_central_system_authentication: 'BasicAuthentication',
          portal_update_endpoint: 'https://dev-emobility-portal-backend.keba.com/update/api/v1',
          connect_2_connector_serial_14: '',
          connect_2_connector_serial_15: '',
          connect_2_connector_serial_16: '',
          connect_2_connector_serial_10: '',
          connect_2_connector_serial_11: '',
          connect_2_connector_serial_12: '',
          connect_2_connector_serial_13: '',
          rfid_authorization_online_mode: 'OnlyLocal',
          ble_fw_version: '10103',
          external_meter_home_grid_provider: '',
          ocpp_charge_point_id: 'P40_28707760',
          ocpp_central_system_path: '',
          rfid_authorization_offline_mode: 'OfflineLocalAuthorization',
          ocpp_proxy_password: '',
          alias_serial_1: '',
          alias_serial_3: '',
          alias_serial_2: '',
          alias_serial_5: '',
          inst_x1_1_mode: 'NONE',
          alias_serial_4: '',
          alias_serial_7: '',
          alias_serial_6: '',
          ocpp_proxy_port: '',
          alias_serial_9: '',
          alias_serial_8: '',
          gsm_apn_password: 'test',
          nominal_voltage: '230',
          max_available_current: '100000',
          connect_2_connector_serial_8: '',
          ssh_server_enable: 'true',
          connect_2_connector_serial_7: '',
          ocpp_charge_point_address: 'localhost',
          pv_threshold_export: '400000',
          connect_2_connector_serial_6: '',
          connect_2_connector_serial_5: '',
          pv_min_share: '0',
          external_meter_home_grid_port: '',
          connect_2_connector_serial_4: '',
          external_meter_home_grid_unit: '',
          connect_2_connector_serial_3: '',
          ocpp_version: 'OCPP_16_PORTAL',
          connect_2_connector_serial_2: '',
          connect_2_connector_serial_1: '28707760',
          external_meter_home_grid_phase2_max_current: '',
          external_meter_home_grid_phase3_max_current: '',
          meter_values_sampled_data:
            'Energy.Active.Import.Register, Current.Offered, Power.Active.Import, Power.Factor, Temperature, Current.Import, Voltage',
          external_meter_home_grid_phase1_max_current: '2000',
          ble_device_pin: 'DummyValue',
          ocpp_charge_point_port: '12801',
          min_default_current: '6000',
          external_meter_home_grid_providers: [
            'ABB | M2M',
            'ABB | M4M',
            'TQ-Systems | EM420 compatible',
            'KOSTAL | KSEM',
            'KeContact E10',
            'Carlo Gavazzi | EM 24',
            'Fronius Smart Meter TS 65A via Symo GEN24',
            'Gossen Metrawatt | EMX228X/EM238X',
            'ABB | B23312-100',
            'Janitza | B23312-10J',
            'Siemens | 7KM2200',
          ],
          ocpp_proxy_address: '',
          ocpp_meter_values_clock_aligned_data_interval: '900',
          inst_x2_mode: 'NONE',
          ocpp_outgoing_tls: 'true',
          connector_phase_rotation: 'L3_L1_L2',
          FastChargingEnable: 'false',
          ocpp_proxy_username: '',
          portal_config_notification_frequency: '30',
          ocpp_central_system_address: '',
          inst_x1_2_value: '32000',
          restapi_alias: 'P40_Rest_12',
          ocpp_central_system_authorization_key: 'DummyAuthorizationKey',
          max_asymmetrical_phase_current: '0',
          alias_serial_15: '',
          alias_serial_16: '',
          ocpp_connection_retry_interval: '30',
          pv_enable: 'false',
          portal_security_profile: '3',
          alias_serial_10: '',
          ocpp_central_system_port: '80',
          alias_serial_13: '',
          pv_pre_charge_time: '0',
          alias_serial_14: '',
          alias_serial_11: '',
          alias_serial_12: '',
          number_of_connectors: '1',
          pv_threshold_import: '400000',
          rfid_authorization_enable: 'false',
          inst_x1_2_mode: 'NONE',
          failsafe_current_serial_8: '0',
          failsafe_current_serial_9: '0',
          failsafe_current_serial_6: '0',
          failsafe_current_serial_7: '0',
          failsafe_current_serial_4: '0',
          failsafe_current_serial_5: '0',
          failsafe_current_serial_2: '0',
          ocpp_central_system_username: '',
          failsafe_current_serial_3: '0',
          failsafe_current_serial_1: '32000',
          ocpp_heartbeat_interval: '600',
          connector_phase_source: 'UDP',
          session_resume_after_power_cut: 'true',
          external_meter_home_grid_max_power: '',
          gsm_enable: 'false',
          gsm_sim_pin: '1111',
          pv_delay: '300',
          ocpp_meter_values_sample_interval: '15',
          connect_2_connector_serial_9: '',
          failsafe_current_serial_10: '0',
          failsafe_current_serial_11: '0',
          failsafe_current_serial_12: '0',
          failsafe_current_serial_13: '0',
          failsafe_current_serial_14: '0',
          failsafe_current_serial_15: '0',
          failsafe_current_serial_16: '0',
          gsm_apn: 'mtel.at',
          inst_x1_1_value: '32000',
          gsm_apn_username: 'testhdhd',
          external_meter_home_grid_enable: 'true',
          portal_config_notification_enabled: 'true',
          inst_settings_locked: '',
          connector_phase_enable: 'false',
          charge_point_model: 'KC-P40-16EU0-C6S1AE00-LS1R1111BL0-WH00',
          ocpp_central_system_password: '',
          TimeSynchronizationMethod: 'OCPP',
          application_version: '1.1.0',
          ocpp_default_token_id: 'predefinedTokenId',
          ocpp_web_socket_ping_interval: '240',
          external_meter_home_grid_offline_max_power: '',
        },
        lastSeenConnected: new Date(), // Current date as placeholder
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED, // Assumed based on no disconnection indicator
        updateIndication: RemoteWallboxUpdateState.LATEST_VERSION, // Assumed latest as no field provided
      },

      {
        name: 'Offline',
        model: 'KC-P40-32EU0-C633ALP0-LS1R1001B00-CL00', // P40, no wifi, no gsm
        state: RemoteWallboxState.OFFLINE,
        lastStateChange: subHours(new Date(), 2),
        lastSeenConnected: subHours(new Date(), 1),
        connectionStatus: RemoteWallboxConnectionStatus.DISCONNECTED,
        serialNumber: '124',
        firmwareVersion: '1.14.0',
        id: 'w-124',
        connectorId: 1,
        requiredFirmwareVersion: '1.15.0',
        updateIndication: RemoteWallboxUpdateState.UPDATE,
        tags: [{ id: '2', name: 'Work' }],
      },
      {
        name: 'Office Indoor',
        state: RemoteWallboxState.ONLINE,
        serialNumber: '125',
        id: 'w-125',
        connectorId: 1,
        firmwareVersion: '1.14.0',
        model: 'KC-P40-E*******-***-PV',
        updateIndication: RemoteWallboxUpdateState.LATEST_VERSION,
        tags: [{ id: '2', name: 'Work' }],
        connectionStatus: RemoteWallboxConnectionStatus.DISCONNECTED,
      },
      {
        name: 'Suspended by wallbox',
        state: RemoteWallboxState.SUSPENDED_BY_WALLBOX,
        serialNumber: '126',
        connectorId: 1,
        id: 'w-126',
        firmwareVersion: '1.14.0',
        model: 'KC-P40-32EU0-C633ALP0-LS1R1111B00-CL00', // P40, wifi and gsm
        updateIndication: RemoteWallboxUpdateState.UPDATING,
        requiredFirmwareVersion: '1.15.0',
        tags: [{ id: '2', name: 'Work' }],
        configuration: { ...fakeWallboxPowerSettings, pv_enable: 'true' },
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
      {
        id: 'e06659e8-27f1-4b1f-8b94-318f9ac58181',
        name: 'HpH',
        connectorId: 1,
        state: RemoteWallboxState.CHARGING,
        lastStateChange: new Date(),
        //accountId: 'bdce1bb4-45c9-47ae-847d-8a90b10c5434',
        serialNumber: '23568848',
        //enrollmentType: 'P30_ENROLLMENT',
        tags: [],
        totalChargedEnergy: 35938900,
        firmwareVersion: '1.18.0',
        model: 'KC-P30-EC2404B2-L0R-GE',
        //currentActiveSession: null,
        //currentActiveTransaction: null,
        pvStatus: {
          // id: '7a948538-9d0d-475c-9d3b-840a6fa2a289',
          status: WallboxPVStatus.PV_PRE_CHARGING,
          endOfBoost: '2024-10-22T11:07:22.503851Z',
        },
        configuration: {
          TimeSynchronizationQuality: 'STRONG',
          alias_serial_2: '',
          alias_serial_3: '',
          external_meter_home_grid_max_power: '100000000',
          ocpp_default_token_id: 'predefinedTokenId',
          alias_serial_1: 'HpH',
          alias_serial_6: '',
          alias_serial_7: '',
          alias_serial_4: '',
          ocpp_charge_point_authentication: 'None',
          alias_serial_5: '',
          ocpp_charge_point_port: '12801',
          ocpp_proxy_password: '',
          alias_serial_8: '',
          alias_serial_9: '',
          meter_values_sampled_data:
            'Energy.Active.Import.Register, Current.Offered, Power.Active.Import, Power.Factor, Temperature, Current.Import, Voltage',
          rfid_authorization_offline_mode: 'OfflineNoAuthorization',
          ocpp_charge_point_username: '',
          external_meter_home_grid_offline_max_power: '7000000',
          gsm_apn_password: 'ppp',
          display_text_card_expired: [
            "'en','EXPIRED card',1,3,0",
            "'ro','Card expirat',1,3,0",
            "'de','ABGELAUFENE Karte',1,3,0",
          ],
          ocpp_meter_values_sample_interval: '15',
          display_text_charging_suspended: [
            "'en','Charging suspended',1,10,0",
            "'ro','Incarcare suspendata',1,10,0",
            "'de','Laden pausiert',1,10,0",
          ],
          ocpp_proxy_port: '',
          pv_enable: 'true',
          max_days_of_logs: '30',
          display_text_card_invalid: [
            "'en','INVALID card',1,3,0",
            "'ro','Card nevalid',1,3,0",
            "'de','UNBEKANNTE Karte',1,3,0",
          ],
          pv_pre_charge_time: '600',
          ocpp_incoming_tls: 'false',
          ocpp_charge_point_address: 'localhost',
          connect_2_connector_serial_2: '',
          connect_2_connector_serial_1: '23568848',
          display_text_card_blocked: [
            "'en','BLOCKED card',1,3,0",
            "'ro','Card blocat',1,3,0",
            "'de','GESPERRTE Karte',1,3,0",
          ],
          charge_point_model: 'KC-P30-EC2404B2-L0R-GE',
          connect_2_connector_serial_6: '',
          connect_2_connector_serial_5: '',
          ocpp_central_system_username: '',
          connect_2_connector_serial_4: '',
          connect_2_connector_serial_3: '',
          display_text_charging_stopped: [
            "'en','Charging stopped',5,10,0",
            "'ro','Incarcare oprita',5,10,0",
            "'de','Laden gestoppt',5,10,0",
          ],
          ssh_server_enable: 'true',
          ocpp_connection_retry_interval: '30',
          connect_2_connector_serial_9: '',
          connect_2_connector_serial_8: '',
          connect_2_connector_serial_7: '',
          connect_2_connector_serial_10: '',
          external_meter_home_grid_phase3_max_current: '100000',
          connect_2_connector_serial_11: '',
          connect_2_connector_serial_16: '',
          ocpp_proxy_enable: 'false',
          external_meter_home_grid_phase1_max_current: '100000',
          connect_2_connector_serial_12: '',
          external_meter_home_grid_phase2_max_current: '100000',
          connect_2_connector_serial_13: '',
          connect_2_connector_serial_14: '',
          connect_2_connector_serial_15: '',
          pv_threshold_export: '400000',
          rfid_authorization_enable: 'false',
          application_version: '1.18.0',
          ocpp_version: 'OCPP_16_PORTAL',
          display_text_card_ok: [
            "'en','ACCEPTED card',1,3,0",
            "'ro','Card acceptat',1,3,0",
            "'de','Karte akzeptiert',1,3,0",
          ],
          display_text_wrong_reservation: [
            "'en','Wrong reservation',1,3,0",
            "'ro','Wrong reservation',1,3,0",
            "'de','Wrong reservation',1,3,0",
          ],
          ocpp_proxy_address: '',
          ocpp_heartbeat_interval: '600',
          ocpp_central_system_address: '',
          pv_delay: '1200',
          external_meter_home_grid_providers: [
            'ABB | M4M',
            'TQ-Systems | EM420 compatible',
            'Siemens | 7KT1260',
            'KOSTAL | KSEM',
            'KeContact E10',
            'Novion HAN/P1',
            'Carlo Gavazzi | EM 24',
            'Fronius Smart Meter TS 65A via Symo GEN24',
            'Gossen Metrawatt | EMX228X/EM238X',
            'Herholdt | ECSEM113',
            'Janitza | ECSEM114MID',
            'ABB | B23312-100',
            'Janitza | B23312-10J',
            'Leviton | S3500',
            'Manglberger | Hofer',
            'Phoenix | EEM-MA371',
            'Siemens | 7KM2200',
          ],
          external_meter_home_grid_enable: 'true',
          ocpp_outgoing_tls: 'false',
          connector_phase_rotation: 'L1_L2_L3',
          ocpp_central_system_authentication: 'None',
          alias_serial_10: '',
          alias_serial_11: '',
          alias_serial_12: '',
          alias_serial_13: '',
          alias_serial_14: '',
          failsafe_current_serial_11: '0',
          external_meter_home_grid_provider: 'KeContact E10',
          failsafe_current_serial_10: '0',
          ocpp_central_system_port: '80',
          failsafe_current_serial_13: '0',
          ocpp_central_system_password: '',
          display_text_checking_card: ["'en','...',0,0,0", "'ro','Cardul se verifica',0,0,0", "'de','...',0,0,0"],
          failsafe_current_serial_12: '0',
          gsm_sim_pin: '',
          failsafe_current_serial_15: '0',
          failsafe_current_serial_14: '0',
          pv_ignore_x1: 'false',
          display_text_insert_plug: [
            "'en','Insert plug',0,5,5",
            "'ro','Introduceti cablul',0,5,5",
            "'de','$      Kabel anstecken',0,5,5",
          ],
          failsafe_current_serial_16: '0',
          max_asymmetrical_phase_current: '6000',
          max_current: '16000',
          connector_phase_source: 'NONE',
          ocpp_meter_values_clock_aligned_data_interval: '900',
          failsafe_current_serial_9: '0',
          session_resume_after_power_cut: 'false',
          pv_threshold_import: '200000',
          failsafe_current_serial_3: '0',
          failsafe_current_serial_4: '0',
          nominal_voltage: '230',
          failsafe_current_serial_1: '16000',
          failsafe_current_serial_2: '0',
          failsafe_current_serial_7: '0',
          portal_config_notification_enabled: 'true',
          failsafe_current_serial_8: '0',
          failsafe_current_serial_5: '0',
          gsm_enable: 'false',
          failsafe_current_serial_6: '0',
          alias_serial_15: '',
          alias_serial_16: '',
          ocpp_central_system_authorization_key: 'DummyAuthorizationKey',
          portal_security_profile: '0',
          portal_update_endpoint: 'https://stage-emobility-backend.keba.com/update/api/v1',
          rfid_authorization_online_mode: 'OnlyLocal',
          gsm_apn_username: 'ppp@A1plus.at',
          display_text_pv_charging: [
            "'en','PV charging',1,10,0",
            "'ro','Incarcare PV',1,10,0",
            "'de','PV Laden',1,10,0",
          ],
          pv_min_share: '10',
          portal_config_notification_frequency: '30',
          ocpp_central_system_path: '',
          connector_phase_enable: 'false',
          display_text_card_please: [
            "'en','$      Swipe card',0,5,5",
            "'ro','$      Cardul, va rog',0,5,5",
            "'de','$      Karte bitte',0,5,5",
          ],
          display_text_charging: [
            "'en','Charging...',1,10,0",
            "'ro','Incarcare...',1,10,0",
            "'de','$      laden...',1,10,0",
          ],
          ocpp_charge_point_id: '23568848',
          ocpp_web_socket_ping_interval: '240',
          asymmetrical_network_enable: 'false',
          display_text_pv_boost_charging: [
            "'en','Boost charge',1,10,0",
            "'ro','Incarcare Boost',1,10,0",
            "'de','Boost Ladung',1,10,0",
          ],
          ocpp_proxy_username: '',
          TimeSynchronizationMethod: 'OCPP',
          max_available_current: '100000',
          gsm_apn: 'a1.net',
          LocalAuthorizeOffline: 'false',
          display_text_reserved_id: [
            "'en','Reserved ID {0}',0,5,5",
            "'ro','Reserved ID {0}',0,5,5",
            "'de','Reserved ID {0}',0,5,5",
          ],
          ocpp_charge_point_interface: 'eth0',
          number_of_connectors: '1',
          min_default_current: '6000',
          display_text_language: 'de',
          external_meter_home_grid_unit: '1',
          ocpp_charge_point_password: '',
          external_meter_home_grid_address: '192.168.0.176',
          external_meter_home_grid_port: '502',
        },
        //isArchived: false,
        lastSeenConnected: new Date(),
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
        //chargingProfile: null,
        requiredFirmwareVersion: undefined,
        //requiredFirmwareVersionId: null,
        updateIndication: RemoteWallboxUpdateState.LATEST_VERSION,
        //chargePointOngoingUpdate: null,
      },
      {
        id: 'e06659e8-27f1-4b1f-8b94-318f9ac581812',
        name: 'Client Wallbox',
        connectorId: 1,
        state: RemoteWallboxState.CHARGING,
        lastStateChange: new Date(),
        //accountId: 'bdce1bb4-45c9-47ae-847d-8a90b10c5434',
        serialNumber: '23568840',
        //enrollmentType: 'P30_ENROLLMENT',
        tags: [],
        totalChargedEnergy: 35938900,
        firmwareVersion: '1.18.0',
        model: 'KC-P30-EC2404B2-L0R-GE',
        //currentActiveSession: null,
        //currentActiveTransaction: null,
        pvStatus: {
          // id: '7a948538-9d0d-475c-9d3b-840a6fa2a289',
          status: WallboxPVStatus.PV_PRE_CHARGING,
          endOfBoost: '2024-10-22T11:07:22.503851Z',
        },
        configuration: {
          TimeSynchronizationQuality: 'STRONG',
          alias_serial_2: '',
          alias_serial_3: '',
          external_meter_home_grid_max_power: '100000000',
          ocpp_default_token_id: 'predefinedTokenId',
          alias_serial_1: 'Client Wallbox',
          alias_serial_6: '',
          alias_serial_7: '',
          alias_serial_4: '',
          ocpp_charge_point_authentication: 'None',
          alias_serial_5: '',
          ocpp_charge_point_port: '12801',
          ocpp_proxy_password: '',
          alias_serial_8: '',
          alias_serial_9: '',
          meter_values_sampled_data:
            'Energy.Active.Import.Register, Current.Offered, Power.Active.Import, Power.Factor, Temperature, Current.Import, Voltage',
          rfid_authorization_offline_mode: 'OfflineNoAuthorization',
          ocpp_charge_point_username: '',
          external_meter_home_grid_offline_max_power: '7000000',
          gsm_apn_password: 'ppp',
          display_text_card_expired: [
            "'en','EXPIRED card',1,3,0",
            "'ro','Card expirat',1,3,0",
            "'de','ABGELAUFENE Karte',1,3,0",
          ],
          ocpp_meter_values_sample_interval: '15',
          display_text_charging_suspended: [
            "'en','Charging suspended',1,10,0",
            "'ro','Incarcare suspendata',1,10,0",
            "'de','Laden pausiert',1,10,0",
          ],
          ocpp_proxy_port: '',
          pv_enable: 'true',
          max_days_of_logs: '30',
          display_text_card_invalid: [
            "'en','INVALID card',1,3,0",
            "'ro','Card nevalid',1,3,0",
            "'de','UNBEKANNTE Karte',1,3,0",
          ],
          pv_pre_charge_time: '600',
          ocpp_incoming_tls: 'false',
          ocpp_charge_point_address: 'localhost',
          connect_2_connector_serial_2: '',
          connect_2_connector_serial_1: '23568848',
          display_text_card_blocked: [
            "'en','BLOCKED card',1,3,0",
            "'ro','Card blocat',1,3,0",
            "'de','GESPERRTE Karte',1,3,0",
          ],
          charge_point_model: 'KC-P30-EC2404B2-L0R-GE',
          connect_2_connector_serial_6: '',
          connect_2_connector_serial_5: '',
          ocpp_central_system_username: '',
          connect_2_connector_serial_4: '',
          connect_2_connector_serial_3: '',
          display_text_charging_stopped: [
            "'en','Charging stopped',5,10,0",
            "'ro','Incarcare oprita',5,10,0",
            "'de','Laden gestoppt',5,10,0",
          ],
          ssh_server_enable: 'true',
          ocpp_connection_retry_interval: '30',
          connect_2_connector_serial_9: '',
          connect_2_connector_serial_8: '',
          connect_2_connector_serial_7: '',
          connect_2_connector_serial_10: '',
          external_meter_home_grid_phase3_max_current: '100000',
          connect_2_connector_serial_11: '',
          connect_2_connector_serial_16: '',
          ocpp_proxy_enable: 'false',
          external_meter_home_grid_phase1_max_current: '100000',
          connect_2_connector_serial_12: '',
          external_meter_home_grid_phase2_max_current: '100000',
          connect_2_connector_serial_13: '',
          connect_2_connector_serial_14: '',
          connect_2_connector_serial_15: '',
          pv_threshold_export: '400000',
          rfid_authorization_enable: 'false',
          application_version: '1.18.0',
          ocpp_version: 'OCPP_16_PORTAL',
          display_text_card_ok: [
            "'en','ACCEPTED card',1,3,0",
            "'ro','Card acceptat',1,3,0",
            "'de','Karte akzeptiert',1,3,0",
          ],
          display_text_wrong_reservation: [
            "'en','Wrong reservation',1,3,0",
            "'ro','Wrong reservation',1,3,0",
            "'de','Wrong reservation',1,3,0",
          ],
          ocpp_proxy_address: '',
          ocpp_heartbeat_interval: '600',
          ocpp_central_system_address: '',
          pv_delay: '1200',
          external_meter_home_grid_providers: [
            'ABB | M4M',
            'TQ-Systems | EM420 compatible',
            'Siemens | 7KT1260',
            'KOSTAL | KSEM',
            'KeContact E10',
            'Novion HAN/P1',
            'Carlo Gavazzi | EM 24',
            'Fronius Smart Meter TS 65A via Symo GEN24',
            'Gossen Metrawatt | EMX228X/EM238X',
            'Herholdt | ECSEM113',
            'Janitza | ECSEM114MID',
            'ABB | B23312-100',
            'Janitza | B23312-10J',
            'Leviton | S3500',
            'Manglberger | Hofer',
            'Phoenix | EEM-MA371',
            'Siemens | 7KM2200',
          ],
          external_meter_home_grid_enable: 'true',
          ocpp_outgoing_tls: 'false',
          connector_phase_rotation: 'L1_L2_L3',
          ocpp_central_system_authentication: 'None',
          alias_serial_10: '',
          alias_serial_11: '',
          alias_serial_12: '',
          alias_serial_13: '',
          alias_serial_14: '',
          failsafe_current_serial_11: '0',
          external_meter_home_grid_provider: 'KeContact E10',
          failsafe_current_serial_10: '0',
          ocpp_central_system_port: '80',
          failsafe_current_serial_13: '0',
          ocpp_central_system_password: '',
          display_text_checking_card: ["'en','...',0,0,0", "'ro','Cardul se verifica',0,0,0", "'de','...',0,0,0"],
          failsafe_current_serial_12: '0',
          gsm_sim_pin: '',
          failsafe_current_serial_15: '0',
          failsafe_current_serial_14: '0',
          pv_ignore_x1: 'false',
          display_text_insert_plug: [
            "'en','Insert plug',0,5,5",
            "'ro','Introduceti cablul',0,5,5",
            "'de','$      Kabel anstecken',0,5,5",
          ],
          failsafe_current_serial_16: '0',
          max_asymmetrical_phase_current: '6000',
          max_current: '16000',
          connector_phase_source: 'NONE',
          ocpp_meter_values_clock_aligned_data_interval: '900',
          failsafe_current_serial_9: '0',
          session_resume_after_power_cut: 'false',
          pv_threshold_import: '200000',
          failsafe_current_serial_3: '0',
          failsafe_current_serial_4: '0',
          nominal_voltage: '230',
          failsafe_current_serial_1: '16000',
          failsafe_current_serial_2: '0',
          failsafe_current_serial_7: '0',
          portal_config_notification_enabled: 'true',
          failsafe_current_serial_8: '0',
          failsafe_current_serial_5: '0',
          gsm_enable: 'false',
          failsafe_current_serial_6: '0',
          alias_serial_15: '',
          alias_serial_16: '',
          ocpp_central_system_authorization_key: 'DummyAuthorizationKey',
          portal_security_profile: '0',
          portal_update_endpoint: 'https://stage-emobility-backend.keba.com/update/api/v1',
          rfid_authorization_online_mode: 'OnlyLocal',
          gsm_apn_username: 'ppp@A1plus.at',
          display_text_pv_charging: [
            "'en','PV charging',1,10,0",
            "'ro','Incarcare PV',1,10,0",
            "'de','PV Laden',1,10,0",
          ],
          pv_min_share: '10',
          portal_config_notification_frequency: '30',
          ocpp_central_system_path: '',
          connector_phase_enable: 'false',
          display_text_card_please: [
            "'en','$      Swipe card',0,5,5",
            "'ro','$      Cardul, va rog',0,5,5",
            "'de','$      Karte bitte',0,5,5",
          ],
          display_text_charging: [
            "'en','Charging...',1,10,0",
            "'ro','Incarcare...',1,10,0",
            "'de','$      laden...',1,10,0",
          ],
          ocpp_charge_point_id: '23568848',
          ocpp_web_socket_ping_interval: '240',
          asymmetrical_network_enable: 'false',
          display_text_pv_boost_charging: [
            "'en','Boost charge',1,10,0",
            "'ro','Incarcare Boost',1,10,0",
            "'de','Boost Ladung',1,10,0",
          ],
          ocpp_proxy_username: '',
          TimeSynchronizationMethod: 'OCPP',
          max_available_current: '100000',
          gsm_apn: 'a1.net',
          LocalAuthorizeOffline: 'false',
          display_text_reserved_id: [
            "'en','Reserved ID {0}',0,5,5",
            "'ro','Reserved ID {0}',0,5,5",
            "'de','Reserved ID {0}',0,5,5",
          ],
          ocpp_charge_point_interface: 'eth0',
          number_of_connectors: '1',
          min_default_current: '6000',
          display_text_language: 'de',
          external_meter_home_grid_unit: '1',
          ocpp_charge_point_password: '',
          external_meter_home_grid_address: '192.168.0.176',
          external_meter_home_grid_port: '502',
        },
        //isArchived: false,
        lastSeenConnected: new Date(),
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
        //chargingProfile: null,
        requiredFirmwareVersion: undefined,
        //requiredFirmwareVersionId: null,
        updateIndication: RemoteWallboxUpdateState.LATEST_VERSION,
        //chargePointOngoingUpdate: null,
      },
      {
        name: 'Suspended by car',
        state: RemoteWallboxState.SUSPENDED_BY_CAR,
        serialNumber: '127',
        id: 'w-127',
        connectorId: 1,
        firmwareVersion: '1.14.0',
        tags: [{ id: '2', name: 'Work' }],
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
      {
        name: 'Reserved',
        state: RemoteWallboxState.RESERVED,
        serialNumber: '128',
        id: 'w-128',
        connectorId: 1,
        firmwareVersion: '1.13.0',
        tags: [{ id: '2', name: 'Work' }],
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
      {
        name: 'Faulted',
        state: RemoteWallboxState.FAULTED,
        serialNumber: '129',
        id: 'w-129',
        connectorId: 1,
        firmwareVersion: '1.1.6',
        tags: [{ id: '2', name: 'Work' }],
        model: 'KC-P30-ESS400E2-M0R',
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
      {
        name: 'Finished',
        state: RemoteWallboxState.FINISHING,
        serialNumber: '130',
        id: 'w-130',
        connectorId: 1,
        firmwareVersion: '1.13.0',
        tags: [{ id: '2', name: 'Work' }],
        model: 'KC-P30-ESS400E2-M0R',
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
      {
        name: 'Unavailable',
        state: RemoteWallboxState.UNAVAILABLE,
        serialNumber: '131',
        id: 'w-131',
        connectorId: 1,
        tags: [{ id: '2', name: 'Work' }],
        model: 'KC-P30-ESS400E2-M0R',
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
      {
        name: 'Preparing',
        state: RemoteWallboxState.PREPARING,
        serialNumber: '132',
        id: 'w-132',
        connectorId: 1,
        firmwareVersion: '1.9.0',
        tags: [{ id: '2', name: 'Work' }],
        model: 'KC-P30-ESS400E2-M0R',
        connectionStatus: RemoteWallboxConnectionStatus.CONNECTED,
      },
    ]);
  }

  getOverview(
    filter?: Filter[],
    sort?: Sort<RemoteWallbox>[],
    pagination?: Pagination
  ): Observable<DataWithCount<RemoteWallbox>> {
    return this.getAll(filter, sort, pagination);
  }

  public saveChargepointAvailabilitySettings(
    wallboxId: string,
    availabilities: RemoteWallboxSettingsChargepointAvailabilityDTO[]
  ): Observable<void> {
    return this.delayResponse(of(void 0));
  }

  public archiveWallbox(wallboxId: string): Observable<void> {
    return this.delete({ id: wallboxId });
  }

  public startPvBoost(wallboxId: string, durationInSeconds: number | null): Observable<boolean> {
    return this.update({
      id: wallboxId,
      pvStatus: {
        status: WallboxPVStatus.PV_BOOST,
        endOfBoost: durationInSeconds != null ? addSeconds(new Date(), durationInSeconds).toISOString() : undefined,
      },
    }).pipe(map(v => true));
  }

  public stopPvBoost(wallboxId: string): Observable<boolean> {
    return this.update({
      id: wallboxId,
      pvStatus: {
        status: WallboxPVStatus.PV_OPTIMIZING,
        endOfBoost: undefined,
      },
    }).pipe(map(v => true));
  }

  public restart(wallboxId: string): Observable<void> {
    return this.delayResponse(of(void 0));
  }

  public getWallboxSettings(wallboxId: string): Observable<RemoteWallboxSettingsDTO> {
    return this.delayResponse(
      of({
        configuration: {
          [RfidKey.ENABLE_RFID_AUTHORIZATION]: 'true',
          [DiagnosticsKey.MAX_DAYS_OF_LOGS]: '2',
          api_alias: 'Test alias',
          [LmgmtKey.PV_ENABLE]: 'false',
          [LmgmtKey.PV_DELAY]: '120',
          [LmgmtKey.PV_THRESHOLD_EXPORT]: '1000',
          [ExtMeterKey.ENABLE_EXTERNAL_METER]: 'false',
          [DisplayTextKey.LANGUAGE]: 'de',
          [NetworkKey.ENABLE_SSH_SERVER]: 'false',
        },
        displayText: [
          {
            lang: 'en',
            key: 'display_text_card_please',
            value: 'Card please',
            minTime: 1,
            maxTime: 3,
            frequency: 0,
          },
          {
            lang: 'de',
            key: 'display_text_card_please',
            value: 'Karte bitte',
            minTime: 1,
            maxTime: 3,
            frequency: 0,
          },
        ],
        chargingNetworkChargepoints: [
          {
            aliasName: 'Test 1',
            availability: RemoteWallboxState.AVAILABLE,
            serialNumber: '123',
            connectorId: 1,
            maxPhases: MaxPhases.THREE,
            phaseUsed: PhaseUsed.L1_L2_L3,
            failsafeCurrent: UnitConverter.ampereToMilliAmpere(32),
            id: '1',
          },
          {
            aliasName: 'Test 2',
            availability: RemoteWallboxState.UNAVAILABLE,
            serialNumber: '456',
            connectorId: 2,
            maxPhases: MaxPhases.ONE,
            phaseUsed: PhaseUsed.L1,
            failsafeCurrent: UnitConverter.ampereToMilliAmpere(8),
            id: '2',
          },
        ],
      })
    );
  }

  public saveWallboxSettings(
    wallboxId: string,
    settings: RemoteWallboxSettingsDTO
  ): Observable<RemoteWallboxSettingsDTO> {
    return this.delayResponse(of(settings));
  }

  public getFirmwareUpdateDetails(
    wallboxId: string,
    language?: string
  ): Observable<RemoteWallboxFirmwareUpdateInfoDTO> {
    return this.delayResponse(
      of({
        updateId: '1',
        updateIndication: RemoteWallboxUpdateState.UPDATE,
        currentVersion: '1.15.0',
        nextAvailableVersion: { id: '1', major: 1, minor: 1, patch: 0 },
        description: 'test de',
      })
    );
  }

  public changeMaxAvailableCurrent(wallboxId: string, maxAvailableCurrent: number): Observable<void> {
    return this.update({
      id: wallboxId,
      configuration: {
        ...fakeWallboxPowerSettings,
        max_available_current: String(maxAvailableCurrent),
      },
    });
  }

  protected override applyFilter(items: RemoteWallbox[], f: Filter): RemoteWallbox[] {
    if (f.fieldName === 'state') {
      const filterStates = f.value as RemoteWallboxState[];
      return items.filter(wallbox => (wallbox.state ? filterStates.includes(wallbox.state) : false));
    }

    if (f.fieldName === 'tags') {
      const filterTagNames = f.value as string[];
      return items.filter(wallbox =>
        filterTagNames.every(filterTagName => wallbox.tags?.some(t => t.name === filterTagName))
      );
    }

    return super.applyFilter(items, f);
  }

  public saveName(wallboxId: string, name: string): Observable<void> {
    return this.update({ id: wallboxId, name });
  }

  public clearPairedDevices(wallboxId: string): Observable<void> {
    return this.update({ id: wallboxId });
  }
}
