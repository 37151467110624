import { HttpClient } from '@angular/common/http';
import { ApiDataService } from './ApiDataService';
import { PortalAPIClient } from './http/PortalAPIClient';
import { ReportAPIClient } from './http/ReportAPIClient';
import { UpdateAPIClient } from './http/UpdateAPIClient';
import { AccountRepository } from './repositories/accounts/AccountRepository';
import { AppVersionControlRepository } from './repositories/appVersionControl/AppVersionControlRepository';
import { ChargingProfilesRepository } from './repositories/charging-profiles/ChargingProfilesRepository';
import { ChargingReportRepository } from './repositories/charging-reports/ChargingReportRepository';
import { ChargingSessionsRepository } from './repositories/charging-sessions/ChargingSessionsRepository';
import { DiagnosticsRepository } from './repositories/diagnostics/DiagnosticsRepository';
import { EnrollmentRepository } from './repositories/enrollment/EnrollmentRepository';
import { NotificationRepository } from './repositories/notification/NotificationRepository';
import { OcppCertificatesRepository } from './repositories/ocpp-certificates/OcppCertificatesRepository';
import { RfidAuthorizationRepository } from './repositories/rfid-authorization/RfidAuthorizationRepository';
import { RfidCardRepository } from './repositories/rfid-cards/RfidCardRepository';
import { TagRepository } from './repositories/tags/TagRepository';
import { UkUpdatesRepository } from './repositories/uk-updates/UkUpdatesRepository';
import { UpdatesRepository } from './repositories/updates/UpdatesRepository';
import { UserManagementRepository } from './repositories/user-management/UserManagementRepository';
import { UserRepository } from './repositories/users/UserRepository';
import { WallboxRepository } from './repositories/wallboxes/WallboxRepository';

export class WebApiDataService extends ApiDataService {
  public static readonly ACCOUNT_ID_PLACEHOLDER = '{accountId}';

  constructor(
    private httpClient: HttpClient,
    baseURL: string
  ) {
    super();

    const wallboxApiClient = new PortalAPIClient(baseURL + '/wallbox/api', httpClient);
    const chargeReportApiClient = new ReportAPIClient(baseURL + '/report/api', httpClient);
    const updateApiClient = new UpdateAPIClient(baseURL + '/update/api', httpClient);

    const accountBasePath = `/accounts/${WebApiDataService.ACCOUNT_ID_PLACEHOLDER}`;

    this.user = new UserRepository(wallboxApiClient);
    this.userManagement = new UserManagementRepository(wallboxApiClient, accountBasePath);
    this.accounts = new AccountRepository(wallboxApiClient);
    this.wallboxes = new WallboxRepository(wallboxApiClient, accountBasePath);
    this.tags = new TagRepository(wallboxApiClient, accountBasePath);
    this.chargingSessions = new ChargingSessionsRepository(wallboxApiClient, accountBasePath);
    this.diagnostics = new DiagnosticsRepository(wallboxApiClient, accountBasePath);
    this.ocppCertificates = new OcppCertificatesRepository(wallboxApiClient, accountBasePath);
    this.chargingProfiles = new ChargingProfilesRepository(wallboxApiClient, accountBasePath);
    this.rfidCards = new RfidCardRepository(wallboxApiClient, accountBasePath);
    this.rfidAuthorization = new RfidAuthorizationRepository(wallboxApiClient, accountBasePath);
    this.updates = new UpdatesRepository(wallboxApiClient, accountBasePath);
    this.ukUpdates = new UkUpdatesRepository(updateApiClient);
    this.notifications = new NotificationRepository(wallboxApiClient);
    this.enrollment = new EnrollmentRepository(wallboxApiClient);

    // APP
    this.appVersionControl = new AppVersionControlRepository(wallboxApiClient);

    // REPORTS
    this.chargingReports = new ChargingReportRepository(chargeReportApiClient, accountBasePath);
  }
}
