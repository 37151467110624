import { HttpClient } from '@angular/common/http';
import { AdvancedHttpClient } from './AdvancedHttpClient';

export class UpdateAPIClient extends AdvancedHttpClient {
  constructor(
    baseUrl: string,
    public override httpClient: HttpClient
  ) {
    super(baseUrl, httpClient);
  }

  protected get defaultVersion(): string {
    return 'v1';
  }
}
