export enum AnalyticsEvents {
  WALLBOX_VIEWED = 'wallbox_viewed',
  WALLBOX_SETTINGS_SAVED = 'wallbox_settings_saved',
  WALLBOX_SETTINGS_ACTION_TRIGGERED = 'wallbox_settings_action_triggered',
  WALLBOX_PASSWORD_CHANGED = 'wallbox_password_changed',
  DESIGN_SYSTEM_OPENED = 'design_system_opened',
  CONNECTION_FAILED = 'connection_failed',
  START_ENROLLMENT = 'start_enrollment',
  START_UNENROLLMENT = 'start_unenrollment',
  ENROLLMENT_TIMED_OUT = 'enrollment_timed_out',
  WALLBOX_PIN_CHANGED = 'wallbox_pin_changed',
  QR_CODE_SCANNER_OPENED = 'qr_code_scanner_opened',
}

export enum P40SetupGuideAnalyticsEvents {
  P40_SETUP_GUIDE = 'p40_setup_guide',
  P40_SETUP_STARTED = 'p40_setup_started',
  P40_LAN_SETUP = 'p40_lan_setup',
  P40_SETUP_BLE_PAIRED = 'p40_setup_ble_paired',
  P40_SETUP_LOGGED_IN = 'p40_setup_logged_in',
  P40_SETUP_SUCCESS = 'p40_setup_success',
  P40_INSTALLER_MODE_FINISHED = 'p40_installer_mode_finished',
}
