import { UnitConverter, WallboxData, WallboxPVStatus, WallboxUtils } from '@kw-shared/common';
import { RemoteWallboxSettingsConfiguration } from '../services';
import { BaseModel } from './BaseModel';
import { RemoteTag } from './RemoteTag';
import { RemoteWallboxChargingSession } from './RemoteWallboxChargingSession';

export interface RemoteWallbox extends BaseModel {
  name?: string;
  serialNumber: string;
  state?: RemoteWallboxState;
  lastStateChange?: Date;
  model?: string;
  connectorId: number;
  masterChargePointId?: string;
  hasClients?: boolean;
  tags?: RemoteTag[];
  clientChargePoints?: string[];

  // extended info is currently only transferred when getting wallbox by id
  rfidCardsCount?: number;
  totalChargedEnergy?: number;

  // firmware version + update
  firmwareVersion?: string;
  updateIndication?: RemoteWallboxUpdateState;
  requiredFirmwareVersion?: string;

  configuration?: RemoteWallboxSettingsConfiguration;

  pvStatus?: {
    status: WallboxPVStatus;
    endOfBoost?: string;
  };
  lastSeenConnected?: Date;
  connectionStatus: RemoteWallboxConnectionStatus;
}

export enum RemoteWallboxConnectionStatus {
  DISCONNECTED = 'DISCONNECTED',
  CONNECTED = 'CONNECTED',
}

export enum RemoteWallboxState {
  AVAILABLE = 'Available',
  PREPARING = 'Preparing',
  CHARGING = 'Charging',
  SUSPENDED_BY_WALLBOX = 'SuspendedEVSE',
  SUSPENDED_BY_CAR = 'SuspendedEV',
  FINISHING = 'Finishing',
  RESERVED = 'Reserved',
  UNAVAILABLE = 'Unavailable',
  FAULTED = 'Faulted',
  ONLINE = 'Online',
  OFFLINE = 'Offline',
}

export enum RemoteWallboxUpdateState {
  LATEST_VERSION = 'LATEST_VERSION',
  CRITICAL_UPDATE = 'CRITICAL_UPDATE',
  UPDATE = 'UPDATE',
  UPDATING = 'UPDATING',
}

export function mapRemoteWallboxToWallboxData(
  remoteWallbox: RemoteWallbox,
  chargingSession?: RemoteWallboxChargingSession
): WallboxData {
  const maxCurrentConfig = remoteWallbox.configuration?.['max_current'];
  let maxCurrent: number = UnitConverter.ampereToMilliAmpere(32); // fallback to 32A

  if (maxCurrentConfig != null) {
    switch (
      typeof maxCurrentConfig // check type of max_current config
    ) {
      case 'number':
        if (!isNaN(maxCurrentConfig)) maxCurrent = maxCurrentConfig;
        break;

      case 'string':
        const parsedMaxCurrent = parseInt(maxCurrentConfig);
        if (!isNaN(parsedMaxCurrent)) maxCurrent = parsedMaxCurrent;
        break;
    }
  }

  const state = WallboxUtils.setRemoteWallboxStateBasedOnConnectionStatus(
    remoteWallbox.connectionStatus,
    remoteWallbox.state
  );

  return {
    alias: remoteWallbox.name,
    state: state,
    serialNumber: remoteWallbox.serialNumber,
    model: remoteWallbox.model ?? '',
    configuration: remoteWallbox.configuration,

    totalActivePower: chargingSession?.totalActivePower ? chargingSession?.totalActivePower : null,
    lines: chargingSession?.lines,
    currentOffered: chargingSession?.currentOffered ? chargingSession?.currentOffered : null,
    totalPowerFactor: chargingSession?.totalPowerFactor ? chargingSession?.totalPowerFactor : null,
    maxCurrent,
    lastSeenConnected: remoteWallbox.lastSeenConnected ?? undefined,
    connectionStatus: remoteWallbox.connectionStatus ?? RemoteWallboxConnectionStatus.DISCONNECTED,
    masterChargePointId: remoteWallbox.masterChargePointId,
    hasClients: remoteWallbox.hasClients ?? false,
    clientChargePoints: remoteWallbox.clientChargePoints,
    connectorId: remoteWallbox.connectorId
  };
}
